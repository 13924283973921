<template>
    <div class="blogger-registration" v-if="translates && translates[langUrl]">
        <div class="blogger-registration__form">
            <div class="blogger-registration__top">
                <div class="blogger-registration__top__logo" v-if="isDesktop"></div>
                <div class="blogger-registration__top__title" v-if="show === 'authorization'">{{ translates[langUrl].pageTitle_enter[lang] }}</div>
                <div class="blogger-registration__top__title" v-if="show === 'registration'">{{ translates[langUrl].pageTitle_create[lang] }}</div>
            </div>
            <div v-if="phoneTextField === true" class="blogger-registration__form__input">
                <ValidationObserver ref="validator_1" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <BasePhoneNumberInput
                            v-model="phone"
                            :error-messages="errors[0]"
                            @update="number($event)"
                            @keyup.enter="savePhone"
                        />
                    </ValidationProvider>
                </ValidationObserver>
                <BaseButton
                    class="button-fill blogger-registration__form__button"
                    @click="savePhone"
                >{{ translates[langUrl].button_continue[lang] }}</BaseButton>
            </div>
            <div v-if="phoneConfirm === true" class="popup-login__number">{{ translates[langUrl].number_part_1[lang] }} {{ phoneWithCode }} {{ translates[langUrl].number_part_2[lang] }}</div>
            <div v-if="phoneConfirm === true" class="popup-login__input">
                <ValidationObserver ref="validator_2" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required|code">
                        <LabelTextField
                            v-model="code"
                            pattern="\d*"
                            :label="translates[langUrl].label_phoneCode[lang]"
                            counter="4"
                            @keyup.enter="confirmPhone"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                    <div v-if="secondsLeft" class="popup-login__send-code">{{ translates[langUrl].code_part_1[lang] }} <span class="popup-login__send-code__timer">{{ translates[langUrl].code_part_2[lang] }} {{ prettyCounter }}</span></div>
                    <div
                        v-else
                        class="popup-login__send-code_active"
                        @click="phoneRepeatSubmit">{{ translates[langUrl].code_part_1[lang] }}</div>
                    <div class="popup-login__buttons">
                        <BaseButton
                            style="width: 100%"
                            class="button-fill"
                            @click="confirmPhone">{{ translates[langUrl].button_continue[lang] }}</BaseButton>
                    </div>
                </ValidationObserver>
            </div>
            <div v-if="emailTextField === true" class="blogger-registration__form__input">
                <ValidationObserver ref="validator_3" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required|email">
                        <LabelTextField
                            :label="translates[langUrl].label_email[lang]"
                            :error-messages="errors[0]"
                            v-model="email"
                            @keyup.enter="saveEmail"
                        />
                    </ValidationProvider>
                </ValidationObserver>
                <BaseButton
                    class="button-fill blogger-registration__form__button"
                    @click="saveEmail"
                >{{ translates[langUrl].button_continue[lang] }}</BaseButton>
            </div>
            <div v-if="emailConfirm === true" class="popup-login__number">{{ translates[langUrl].email_part_1[lang] }} {{ email }} {{ translates[langUrl].email_part_2[lang] }}</div>
            <div v-if="emailConfirm === true" class="popup-login__input">
                <ValidationObserver ref="validator_4" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required|code">
                        <LabelTextField
                            v-model="emailCode"
                            pattern="\d*"
                            :label="translates[langUrl].label_emailCode[lang]"
                            counter="4"
                            @keyup.enter="confirmEmail"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                    <BaseButton
                        style="width: 100%"
                        class="button-fill"
                        @click="confirmEmail"
                    >{{ translates[langUrl].button_continue[lang] }}</BaseButton>
                </ValidationObserver>
            </div>
            <div v-if="lastStep === true" class="blogger-registration__form__input">
                <ValidationObserver ref="validator_5" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <LabelTextField
                            :label="translates[langUrl].firstname[lang]"
                            :error-messages="errors[0]"
                            v-model="firstname"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <LabelTextField
                            :label="translates[langUrl].lastname[lang]"
                            :error-messages="errors[0]"
                            v-model="lastname"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" rules="required|social">
                        <LabelTextField
                            :class="{ 'error--text': serverErrors.social }"
                            :details="serverErrors.social"
                            v-model="social"
                            :label="translates[langUrl].social[lang]"
                            :error-messages="errors[0] || serverErrors.social"
                            :counter="100"
                            @keyup.enter="saveUser"
                        />
                    </ValidationProvider>
                </ValidationObserver>
                <BaseButton
                    class="button-fill blogger-registration__form__button"
                    @click="saveUser"
                >{{ translates[langUrl].button_continue[lang] }}</BaseButton>
            </div>

            <div class="blogger-registration__form__account"  v-if="show === 'authorization'">
                {{ translates[langUrl].noAccount[lang] }} 
                <span 
                    class="blogger-registration__form__account__link"
                    @click="$router.push({ name: 'blogger-registration' })">{{ translates[langUrl].button_bloggerRegistration[lang] }}</span>
            </div>
            <div class="blogger-registration__form__account"  v-if="show === 'registration'">
                {{ translates[langUrl].haveAccount[lang] }} 
                <span 
                    class="blogger-registration__form__account__link"
                    @click="$router.push({ name: 'blogger-authorization' })">{{ translates[langUrl].button_bloggerAuthorization[lang] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { isMobile } from '@/helpers';

    import BaseButton from '../../components/common/BaseButton';
    import LabelTextField from '../../components/common/LabelTextField';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    export default {
        name: 'Registration',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            LabelTextField,
            BasePhoneNumberInput
        },
        data: () => ({
            isMobile,
            isDesktop: false,
            phone: '',
            phoneWithCode: '',
            // phoneFormatted: '',
            code: '',
            email: '',
            emailCode: '',
            firstname: '',
            lastname: '',
            social: '',
            phoneTextField: true,
            phoneConfirm: false,
            emailTextField: false,
            emailConfirm: false,
            nameTextField: false,
            socialTextField: false,
            lastStep: false,
            secondsLeft: 0,
            intervalId: undefined,
            serverErrors: {
                social: ''
            },
            show: 'authorization',
            langUrl: 'Auth'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            canCodeRequest() {
                return this.model.phone.replace(/[^\d]/g, '').length === 10 && this.model.name;
            },
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }

                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            this.show = this.$route.meta.show || 'authorization';
            await store.dispatch('auth/logout');
            store.commit('trips/CLEAR_ENTITIES');
            store.commit('events/CLEAR_ALL');
            this.$root.$once('authorized', async () => {
                if(this.user.blogger) {
                    await this.$router.push({ name: 'blogger-audience' });
                } else {
                    await this.$router.push({ name: 'home' });
                }                    
            });
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = !isMobile();
            },
            async savePhone() {
                const valid = await this.$refs['validator_1'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: this.phoneWithCode, blogger: true });
                    this.phoneTextField = false;
                    this.phoneConfirm = true;
                }
                await this.startTimer();
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/login', { phone: '+' + this.phone.replace(/[^\d]/g, ''), blogger: true });
            },
            async confirmPhone() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    await store.dispatch('events/summary');
                    await store.dispatch('events/fetch', {});
                    if(
                        this.user.blogger &&
                        this.user.firstname && this.user.firstname !== '' && 
                        this.user.lastname && this.user.lastname !== '' && 
                        this.user.email && this.user.email !== '' && 
                        this.user.social && this.user.social !== ''
                    ) {
                        await this.$router.push({ name: 'blogger-audience' });
                    } else {
                        this.phoneConfirm = false
                        this.emailTextField = true;
                    }
                }
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            },
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 60;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async saveEmail() {
                const valid = await this.$refs['validator_3'].validate();
                if(valid) {
                    store.commit('auth/SET_USER', { ...this.user, email: this.email, blogger: true });
                    await store.dispatch('auth/save');
                    this.emailTextField = false;
                    // this.emailConfirm = true;
                    this.lastStep = true;
                }
                // await this.startTimer();
            },
            // async confirmEmail() {
            //     const valid = await this.$refs['validator_4'].validate();
            //     if(valid) {
            //         await store.dispatch('auth/emailConfirm', { code: this.emailCode });
            //         this.emailConfirm = false;
            //         this.lastStep = true;
            //     }
            //     // await this.startTimer();
            // },
            async saveUser() {
                const valid = await this.$refs['validator_5'].validate();
                if(valid) {
                    const response = await store.dispatch('auth/checkSocial', { phone: '+' + this.phone.replace(/[^\d]/g, ''), social: this.social });
                    if(response.errors) {
                        for(const error of response.errors) {
                            this.serverErrors[error.field] = error.message;
                        }
                    } else {
                        store.commit('auth/SET_USER', { ...this.user, firstname: this.firstname, lastname: this.lastname, social: this.social, blogger: true });
                        await store.dispatch('auth/save');
                        await this.$router.push({ name: 'blogger-audience' });
                    }
                }
            },
            async back() {
                this.phoneConfirm = false
            },
        }
    }
</script>

<style lang="scss">
.blogger-registration {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 32px;

        &__logo {
            height: 33px;
            width: 300px;
            background: url(../../assets/Main-logo.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 40px;
        }

        &__title {
            display: flex;
            flex-direction: column;
            width: 280px;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #273155;
        }
    }
    &__form {
        // max-height: 400px;
        width: 600px;
        padding: 60px;
        margin: 60px 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        background: #FFFFFF;
        box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
        border-radius: 24px;

        @media all and (max-width: 1024px) {
            width: 100%;
            padding: 60px 20px;
            margin: 0;
        }

        &__open-button {
            display: flex;
            justify-content: center;
            padding: 10px 20px;
            border: 1px solid #F1F3F5;
            box-sizing: border-box;
            border-radius: 12px;
            background: none;
            cursor: pointer
        }

        &__number {
            padding-top: 10px;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            opacity: 0.5;
            // padding-bottom: 10px;
        }

        &__input {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
        }

        &__send-code {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin: 7px auto 20px 10px;
            &__timer {
                opacity: 0.5;
            }
            &_active {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-decoration: underline;
                // opacity: 0.5;
                color: #3A4256;
                margin: 7px auto 20px 10px;
                cursor: pointer;
            }
        }

        &__button {
            margin-top: 32px;
            width: 100%;
            height: 54px !important;
        }

        &__account {
            margin-top: 20px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #616D89;

            &__link {
                color: #4400d2;
                cursor: pointer;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 15px
        }
    }
}

</style>
